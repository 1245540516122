import { useEffect, useState } from 'react';
import {
  clearAxiosHeader,
  setAxiosHeader,
  setAxiosInterceptors,
} from '../../../modules/api/axiosInterceptors';
import { useRouter } from 'next/router';
import { getBroadcastChannel } from 'src/modules/broadcastChannel/broadcastChannel';
import SSOConf from '@/constants/SSOConf';
import appStorage from '@/modules/storage/appStorage';
import dayjs from 'dayjs';
import getMe from '@/api/auth/getMe.endpoint';

const contextName = 'globalContext';
export const globalContextChannel = contextName;

export default function useGlobalContext({ referer }) {
  const router = useRouter();
  const [state, setState] = useState({
    loaded: false,
    accessToken: null,
    user: null,
    impersonatedBy: [],
    isSubscriptionActive: false,
    isSubscriptionAboutToExpire: false,
    isTeacherPro: false,
    subscription: null,
    nextUrl: null,
    availableLanguages: ['es'],
    selectedLanguage: 'es',
    alertsCount: 0,
  });
  const [showSessionModal, setShowSessionModal] = useState(false);

  const actions = {
    setState: (newState) => {
      setState((previousState) => {
        const newCombinedState = { ...previousState, ...newState };
        appStorage('localStorage').setItem(
          contextName,
          JSON.stringify(newCombinedState)
        );
        return newCombinedState;
      });
    },
    publicActions: {
      refreshUser: async () => {
        try {
          const user = await getMe();
          actions.setState({
            user,
            ...getSubscriptionState(user),
          });
        } catch (e) {
          console.log('error', e);
        }
      },
      setSession: ({ accessToken, user, impersonatedBy, nextUrl }) => {
        setAxiosHeader(accessToken);
        actions.setState({
          accessToken,
          user,
          impersonatedBy,
          loading: false,
          nextUrl,
          alertsCount: 0,
          ...getSubscriptionState(user),
        });
        const channel = getBroadcastChannel(globalContextChannel);
        channel.postMessage({ message: 'NEW_SESSION', user });
      },
      clearSession: (notify = true) => {
        clearAxiosHeader();
        setShowSessionModal(false);
        actions.setState({
          accessToken: null,
          user: null,
          impersonatedBy: [],
          isSubscriptionActive: false,
          isSubscriptionAboutToExpire: false,
          isTeacherPro: false,
          subscription: null,
          nextUrl: null,
          alertsCount: 0,
        });
        if (notify) {
          const channel = getBroadcastChannel(globalContextChannel);
          channel.postMessage({ message: 'LOGOUT' });
        }
      },
      setSelectedLanguage: (selectedLanguage) => {
        actions.setState({ selectedLanguage });
      },
      manageExpiredSession: () => {
        if (state.user?.teacher || state.user?.student) {
          setShowSessionModal(true);
        } else {
          router.push(
            `/logout?errorMessage=${encodeURIComponent(
              'Your session has expired'
            )}`
          );
        }
      },
      refreshSession: async () => {
        const user = state.user?.student || state.user?.teacher;
        const externalSsoProvider = user?.ssoProviders?.find(
          (ssoProvider) => ssoProvider.provider != 'password'
        );

        let loginUrl = null;
        setShowSessionModal(false);
        if (externalSsoProvider) {
          loginUrl = SSOConf[externalSsoProvider.provider].getLoginUrl({
            isStudent: !!state.user.student,
          });
          router.push(loginUrl);
        } else {
          router.push(
            `/login?userType=${
              state.user ? (state.user.teacher ? 'teacher' : 'student') : ''
            }`
          );
          actions.publicActions.clearSession();
        }
      },
      closeModal: () => {
        setShowSessionModal(false);
        router.push(
          `/login?userType=${
            state.user ? (state.user.teacher ? 'teacher' : 'student') : ''
          }`
        );
        actions.publicActions.clearSession();
      },
      hasPermission: (permission) => {
        const user =
          state.user?.teacher || state.user?.student || state.user?.admin;
        return user?.data?.permissions?.some(
          (userPermission) =>
            userPermission == permission || userPermission == 'all'
        );
      },
      setAlertsCount: (alertsCount) => {
        actions.setState({ alertsCount });
      },
    },
  };

  useEffect(() => {
    const savedState = appStorage('localStorage').getItem(contextName);
    if (savedState) {
      const savedStateObject = JSON.parse(savedState);
      setAxiosHeader(savedStateObject.accessToken);
      actions.setState({
        ...savedStateObject,
        loaded: true,
      });
      if (savedStateObject?.user) {
        actions.publicActions.refreshUser();
      }
    } else {
      actions.setState({ loaded: true });
    }
    setShowSessionModal(false);
  }, []);

  useEffect(() => {
    setAxiosInterceptors(actions.publicActions);
  }, [state.user]);

  useEffect(() => {
    function onGlobalContextMessage(data) {
      const logoutReceived = data.message == 'LOGOUT';
      const newSessionReceived = data.message == 'NEW_SESSION';
      let isSameUser = false;
      if (newSessionReceived) {
        if (data.user.teacher) {
          isSameUser = data.user.teacher.id == state.user?.teacher?.id;
        } else if (data.user.student) {
          isSameUser = data.user.student.id == state.user?.student?.id;
        } else if (data.user.admin) {
          isSameUser = data.user.admin?.id == state.user?.admin?.id;
        }
      }
      if (logoutReceived || (newSessionReceived && !isSameUser)) {
        actions.publicActions.clearSession(false);
        router.push('/login');
      }
    }

    const channel = getBroadcastChannel(globalContextChannel);
    channel.addEventListener('message', onGlobalContextMessage);

    return () => {
      channel.removeEventListener('message', onGlobalContextMessage);
    };
  }, [state.user]);

  useEffect(() => {
    if (state.loaded) {
      const user =
        state.user?.teacher || state.user?.student || state.user?.admin;

      const newState = {
        userName: user?.firstName ? `${user.firstName} ${user.lastName}` : '',
      };
      if (state.user?.student) {
        const availableLanguages = getAvailableLanguages(
          state.user?.student?.subscriptions
        );
        newState.availableLanguages = availableLanguages;
      }

      if (!state.user) {
        newState.availableLanguages = ['es', 'fr'];
      }

      actions.setState(newState);

      if (!state.user && referer) {
        redirectByReferer(referer, router);
      }
    }
  }, [state.loaded, state.user, referer]);

  useEffect(() => {
    if (state.availableLanguages?.length) {
      actions.setState({ selectedLanguage: state.availableLanguages[0] });
    }
  }, [state.availableLanguages]);

  return {
    state: {
      ...state,
      showSessionModal,
    },
    actions: actions.publicActions,
  };
}

function redirectByReferer(referer, router) {
  if (
    referer?.includes('classroom.google.com') &&
    router?.asPath?.includes('integrations')
  ) {
    const loginUrl = SSOConf.google.getLoginUrl({
      isStudent: true,
      redirectTo: window.location.href,
      redirectErrorTo: `${window.location.origin}/login`,
    });
    router.push(loginUrl);
  }
}

function getSubscriptionState(user) {
  let subscriptionState = {};
  if (user?.teacher) {
    const { subscription } = user.teacher;
    if (subscription) {
      const availableLanguages = getAvailableLanguages([subscription]);

      const isAboutToExpire =
        subscription.status == 'active' &&
        subscription.recurrence == 'none' &&
        dayjs().add(8, 'day').isAfter(subscription.expirationDate, 'day');

      subscriptionState = {
        subscription,
        isSubscriptionActive: subscription?.status == 'active',
        isSubscriptionAboutToExpire: isAboutToExpire,
        isTeacherPro: subscription?.subscriptionType?.code == 'teacherPro',
        availableLanguages,
      };
    } else {
      subscriptionState = {
        subscription: null,
        isSubscriptionActive: false,
        isSubscriptionAboutToExpire: false,
        isTeacherPro: false,
        availableLanguages: ['es'],
      };
    }
  }
  return subscriptionState;
}

function getAvailableLanguages(subscriptions = []) {
  const availableLanguages = [];
  for (const subscription of subscriptions) {
    const languages =
      subscription.language == 'all' ? ['es', 'fr'] : [subscription.language];
    availableLanguages.push(...languages);
  }
  return availableLanguages;
}
