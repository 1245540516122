import useCustomHook from '@/hooks/useCustomHook';
import openAPIErrorNotification from '@/shared/APIErrorNotification/APIErrorNotification';
import { createContext, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { StudentContext } from '@/shared/StudentContext/StudentContext';
import getGamificationProgress from '@/api/gamificationProgress/getGamificationProgress.endpoint';
import saveGamificationProgress from '@/api/gamificationProgress/saveGamificationProgress.endpoint';
import { GlobalContext } from '@/shared/GlobalContext/GlobalContext';

const GamificationContext = createContext({});

export function GamificationContextProvider({ children }) {
  const studentContext = useContext(StudentContext);
  const globalContext = useContext(GlobalContext);

  const { state, actions } = useCustomHook({
    defaultState: {
      loading: false,
      xp: null,
      stars: null,
      isGamificationStudent: false,
      gainedStars: 0,
      animatingStars: false,
    },
    actions: {
      loadData: async () => {
        try {
          actions.setState({ loading: true });
          const response = await getGamificationProgress();

          const xp = response.xp || 0;
          const stars = response.stars || 0;

          actions.setState({
            xp,
            stars,
            loading: false,
          });
        } catch (error) {
          actions.setState({ loading: false });
          openAPIErrorNotification({
            title: 'There was an error',
            description: 'There was a problem loading the your xp and stars',
            errorFeedback: error.feedbackData,
          });
          Sentry.captureException(error);
        }
      },
      putData: async ({
        activityId,
        miniactivityType,
        level,
        progress,
        correctAnswers,
      }) => {
        if (state.isGamificationStudent) {
          try {
            const prevStars = state.stars;
            const response = await saveGamificationProgress(activityId, {
              miniactivityType,
              level,
              progress,
              correctAnswers,
            });
            const { xp, stars } = response;
            actions.setState({
              xp,
              stars,
              gainedStars: stars - prevStars,
              animatingStars: true,
            });
          } catch (error) {
            Sentry.captureException(error);
            openAPIErrorNotification({
              title: 'There was an error',
              description: 'There was a problem saving the your xp and stars',
              errorFeedback: error.feedbackData,
            });
          }
        }
      },

      onStarsAnimationEnd: () => {
        actions.setState({ gainedStars: 0, animatingStars: false });
      },
    },
  });

  useEffect(() => {
    if (
      studentContext.state.loaded &&
      globalContext.state.loaded &&
      globalContext.state.user?.student
    ) {
      actions.setState({
        isGamificationStudent:
          globalContext.state.user.student.subscriptions?.[0]?.teacher?.data
            ?.preferences?.gamificationEnabled &&
          studentContext.state.currentClass &&
          !studentContext.state.isSharedStudent,
      });
    }
  }, [
    studentContext.state.loaded,
    studentContext.state.currentClass,
    studentContext.state.isSharedStudent,
    globalContext.state.loaded,
    globalContext.state.user,
  ]);

  useEffect(() => {
    if (state.isGamificationStudent) {
      actions.loadData();
    }
  }, [state.isGamificationStudent]);

  return (
    <GamificationContext.Provider
      value={{
        state,
        actions,
      }}
    >
      {children}
    </GamificationContext.Provider>
  );
}

export const useGamificationContext = () => useContext(GamificationContext);
